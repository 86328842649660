<template>
  <div class="wrapper">
    <h5>Bet Slip</h5>
    <span>Money Line</span>
    <b-form-select style="margin-bottom: 10px;" v-model="winner" :options="winnerOptions" size="sm"></b-form-select>
    <span>Spread</span>
    <b-form-select style="margin-bottom: 10px;" v-model="spread" :options="spreadOptions" size="sm"></b-form-select>
    <span>Total Points</span>
    <b-form-select v-model="total" :options="totalOptions" size="sm"></b-form-select>
    <hr>
    <p>Potential Earnings: ${{money}}</p>
    <b-button variant="danger" style="margin-top:10px" class="btn-block" @click="bet">Place Wager</b-button>
    <p>{{message}}</p>
  </div>
</template>

<script>
import { api } from "@/utils/api";
export default {
  name: "Bet",
  props: {
    challenge: null
  },
  data() {
    return {
      winner: null,
      winnerOptions: [{ value: null, text: "Select the Money Line" }],
      spread: null,
      spreadOptions: [{ value: null, text: "Select the Spread" }],
      total: null,
      totalOptions: [
        { value: null, text: "Select the Total Points" },
        { value: "OV", text: "Over" },
        { value: "UN", text: "Under" }
      ],
      message: ""
    };
  },
  computed: {
    // a computed getter
    money() {
      var risking = 0
      
      // MONEY LINE
      if (this.winner !== null){
        if (this.challenge.line.id === this.winner) {
          risking += this.convert(this.challenge.lineFOdds)
        } else {
          risking += this.convert(this.challenge.lineAOdds)
        }
      }

      if (this.spread !== null) {
        if (this.challenge.spread.id === this.spread) {
          risking += this.convert(this.challenge.spreadFOdds)
        } else {
          risking += this.convert(this.challenge.spreadAOdds)
        }
      }

      if(this.total !== null) {
        if ('OV' === this.total) {
          risking += this.convert(this.challenge.totalFOdds)
        } else {
          risking += this.convert(this.challenge.totalAOdds)
        }
      }

      return (risking).toFixed(2)
    }
  },
  mounted() {
    this.winnerOptions.push({
      value: this.challenge.home.id,
      text: this.challenge.home.name
    });
    this.winnerOptions.push({
      value: this.challenge.away.id,
      text: this.challenge.away.name
    });

    this.spreadOptions.push({
      value: this.challenge.home.id,
      text: this.challenge.home.name
    });
    this.spreadOptions.push({
      value: this.challenge.away.id,
      text: this.challenge.away.name
    });
  },
  methods: {
    convert(odds) {
      if (odds > 0) {
        return odds/100
      } else {
        return 100/odds*-1
      }
    },
    bet() {
      let self = this;
      if (this.winner === null) {
        this.message = "Bad winner";
      } else if (this.spread === null) {
        this.message = "Bad spread";
      } else if (this.total !== "OV" && this.total !== "UN") {
        this.message = "Bad total";
      } else {
        api(
          `mutation { bet(cid:"${this.$route.params.id}", lin:${this.winner}, spd:${this.spread}, tot:"${this.total}"){ code message } }`
        ).then(data => {
          if (data.bet.code === 0) {
            self.needtobet = false;
            self.message = "Bet placed successfully";
            self.$emit("clicked", "someValue");
          } else {
            self.message = data.bet.message;
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.wrapper {
  border-radius: 4px;
  /* background-color: #242424; */
  padding: 10px;
  border: 1px solid #303030;
  /* box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22); */
  margin-bottom: 10px;
}
</style>