import { HorizontalBar } from "vue-chartjs";

export default {
  extends: HorizontalBar,
  props: {
    challenge: null,
    bets: null
  },
  data() {
    return {
      dataset: [],
      favor: [],
      against: []
    };
  },
  created() {
    var tl = 0;
    var ts = 0;
    var tp = 0;
    this.bets.forEach(element => {
      if (element.winner.name === this.challenge.line.name) tl += 1;
      if (element.spread.name === this.challenge.line.name) ts += 1;
      if (element.totalpoints === "OV") tp += 1;
    });
    let numBets = this.bets.length;
    this.favor = [tl, ts, tp];
    this.against = [tl - numBets, ts - numBets, tp - numBets];
  },
  mounted() {
    this.renderChart(
      {
        labels: ["Line", "Spread", "Points"],
        datasets: [
          {
            label: "In Favor",
            backgroundColor: "#F1BE48",
            data: this.favor
          },
          {
            label: "Against",
            backgroundColor: "#C8102E",
            data: this.against
          }
        ]
      },
      {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                stepSize: 1
              }
            }
          ],
          yAxes: [
            {
              stacked: true,

              barPercentage: 0.4
            }
          ]
        }
      }
    );
  }
};
