<template>
    <div class="row">
        <div class="col-5" style="text-align: right;">
            {{challenge.away.abbrev}}
            <img class="logo" v-bind:src="'/server/logos/'+challenge.away.logo" height="30" />
        </div>
        <div class="col-2" style="text-align: center;">
           @
        </div>
        <div class="col-5" style="text-align: left;">
            <img class="logo" v-bind:src="'/server/logos/'+challenge.home.logo" height="30" />
            {{challenge.home.abbrev}}
        </div>
    </div>
</template>

<script>
export default {
  name: "NewFaceoff",
  props: {
    challenge: null,
  },
  methods: {
    viewChallenge(cid) {
      this.$router.push({
        name: "Challenge",
        params: { id: cid },
      });
    },
  }
};
</script>

<style scoped>
.center-text {
  text-align: center;
}
.vegas {
  margin: 5px;
}
.challenge {
  text-align: center;
}
.at {
  padding: 15px;
  font-size: large;
  font-weight: 700;
  font-size: x-large;
}
.col-3 {
  padding: 2px;
}
.odd-container {
  position:relative; 
  border: 1px solid gray;
  border-radius: 5px;
  margin: 1px;
  height: 100%;
  width: 100%;
}
.hit {
  border: 2px solid green;
}
.odds {
  vertical-align: middle;
  height: 100%;
  width: 100%;
  position: absolute;
  text-align: center;
}
.money-line {
  font-size: 10px;
  color: gray;
}
</style>