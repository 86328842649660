<template>
  <div>
    <div class="wrapper">
      <div class="row">
        <div class="col-6">
          <div class="logo-box">
            <img class="logo" v-bind:src="'/server/logos/'+challenge.away.logo" height="25" />
          </div>
          <!-- MONEY LINE AWAY -->
          <div class="stats">
            <p class="alignleft">Money Line</p>
            <p class="alignright">{{split.away.tl}}</p>
          </div>
          <div class="progress">
            <div class="progress-bar" role="progressbar" :style="{width: (split.away.tl/split.numbets*100)+'%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <!-- SPREAD AWAY -->
          <div class="stats">
            <p class="alignleft">Spread</p>
            <p class="alignright">{{split.away.ts}}</p>
          </div>
          <div class="progress">
            <div class="progress-bar" role="progressbar" :style="{width: (split.away.ts/split.numbets*100)+'%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <!-- Over -->
          <div class="stats">
            <p class="alignleft">Over</p>
            <p class="alignright">{{split.away.tp}}</p>
          </div>
          <div class="progress">
            <div class="progress-bar" role="progressbar" :style="{width: (split.away.tp/split.numbets*100)+'%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>

        <!-- HOME TEAM -->
        <div class="col-6">
          <div class="logo-box" style="text-align:right;">
            <img class="logo" v-bind:src="'/server/logos/'+challenge.home.logo" height="25" />
          </div>
          <!-- HOME MONEY LINE -->
          <div class="stats">
            <p class="alignleft">{{split.home.tl}}</p>
            <p class="alignright">Money Line</p>
          </div>
          <div class="progress">
            <div class="progress-bar" role="progressbar" :style="{width: (split.home.tl/split.numbets*100)+'%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <!-- HOME SPRED -->
          <div class="stats">
            <p class="alignright">Spread</p>
            <p class="alignleft">{{split.home.ts}}</p>
          </div>
          <div class="progress">
            <div class="progress-bar" role="progressbar" :style="{width: (split.home.ts/split.numbets*100)+'%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <!-- Under -->
          <div class="stats">
            <p class="alignleft">{{split.home.tp}}</p>
            <p class="alignright">Under</p>
          </div>
          <div class="progress">
            <div class="progress-bar" role="progressbar" :style="{width: (split.home.tp/split.numbets*100)+'%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- <hr /> -->

    <div class="wrapper">
      <table class="table table-sm">
        <thead>
          <tr>
            <!-- <th width="10%" style="text-align:left;" scope="col"></th> -->
            <th width="40%" scope="col"></th>
            <th width="20%" scope="col">Line</th>
            <th width="20%" scope="col">Spread</th>
            <th width="20%" scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="bet in bets" :key="bet.id">          
            <!-- <td>
              <img v-if="bet.gambler.user.profile.picture" v-bind:src="'/server/1900eab6c028483d7126599ee6f50de0d27907b5c65fa90524580b4b0f9852b/'+bet.gambler.user.profile.picture" class="picture" :style="{border: '2px solid' + bet.gambler.user.profile.color}" />
            </td> -->
            <td scope="row">
                {{bet.gambler.user.firstName}} {{bet.gambler.user.lastName.charAt(0)}}.
              <br>
              <span v-if="challenge.results">
                <span v-if="winnings(bet) > 0" style="color:#01bb8c;;">&#8593;{{winnings(bet)}}</span>
                <span v-else  style="color:#e74c3c;">&#8595;{{winnings(bet)}}</span>
                <span>/</span>
              </span>
              <span>${{risking(bet)}}</span>
            </td>
            <!-- MONEY LINE -->
            <td>
              <div class="odd-container" :class="{hit: (challenge.results && challenge.results.winner.name === bet.winner.name)}">
                <div class="odds">
                  {{bet.winner.abbrev}}
                  <p class="money-line" v-if="challenge.line.name === bet.winner.name">{{perbet(challenge.lineFOdds)}}%</p>
                  <p class="money-line" v-else>{{perbet(challenge.lineAOdds)}}%</p>
                </div>
              </div>    
            </td>

            <!-- SPREAD -->
            <td>
              <div class="odd-container" :class="{hit: (challenge.results && challenge.results.spread.name === bet.spread.name)}">
                <div class="odds">
                  {{bet.spread.abbrev}}
                  <p class="money-line" v-if="challenge.line.name === bet.spread.name">{{perbet(challenge.spreadFOdds)}}%</p>
                  <p class="money-line" v-else>{{perbet(challenge.spreadAOdds)}}%</p>
                </div>
              </div>
            </td>

            <!-- TOTAL -->
            <td>
              <div class="odd-container" :class="{hit: (challenge.results && challenge.results.totalpoints === bet.totalpoints)}">
                <div class="odds">
                  {{bet.totalpoints}}
                  <p class="money-line" v-if="bet.totalpoints === 'OV'">{{perbet(challenge.totalFOdds)}}%</p>
                  <p class="money-line" v-else>{{perbet(challenge.totalAOdds)}}%</p>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <span style="color: gray"><i>* All bets are valued based off a $1 entry.</i></span>
    </div>
  </div>
</template>

<script>
import { api } from "@/utils/api";
import BetsGraph from "@/components/graphs/BetsGraph";

export default {
  name: "Bets",
  components: {
    BetsGraph
  },
  props: {
    challenge: {
      required: true
    }
  },
  data() {
    return {
      bets: null,
      split: {
        home: {
          tl: 0,
          ts: 0,
          tp: 0,
        },
        away: {
          tl: 0,
          ts: 0,
          tp: 0
        },
        numbets: 0
      },
      results: null
    };
  },
  created() {
    let self = this;
    api(
      `query { bets(cid:"${this.challenge.id}"){ id gambler { user { firstName lastName profile { picture color } } } winner { name abbrev } spread { name abbrev } totalpoints } result(cid:"${this.challenge.id}"){ winner{ name } spread{ name } totalpoints } }`
    ).then(data => {
      self.bets = data.bets;
      self.results = data.result;
      this.bets.forEach(element => {
        if (element.winner.name === this.challenge.home.name) {
          self.split.home.tl += 1;
        } else {
          self.split.away.tl += 1;
        }
        if (element.spread.name === this.challenge.home.name) {
          self.split.home.ts += 1;
        } else {
          self.split.away.ts += 1;
        }
        if (element.totalpoints === "OV") {
          self.split.away.tp += 1;
        } else {
          self.split.home.tp += 1;
        }
      });
      self.split.numbets = this.bets.length;
      });
  },
  methods: {
    convert(odds) {
      if (odds > 0) {
        return odds/100
      } else {
        return 100/odds*-1
      }
    },
    perbet(odds) {
      return (this.convert(odds)).toFixed(2)
    },
    risking(bet) {

      var risking = 0
      // MONEY LINE
      if (this.challenge.line.name === bet.winner.name) {
        risking += this.convert(this.challenge.lineFOdds)
      } else {
        risking += this.convert(this.challenge.lineAOdds)
      }

      if (this.challenge.spread.name == bet.spread.name) {
        risking += this.convert(this.challenge.spreadFOdds)
      } else {
        risking += this.convert(this.challenge.spreadAOdds)
      }

      if (this.challenge.totalpoints == "OV") {
        risking += this.convert(this.challenge.totalFOdds)
      } else {
        risking += this.convert(this.challenge.totalAOdds)
      }

      return (risking).toFixed(2)
    },

    winnings(bet) {

      var earnings = 0
      // MONEY LINE
      if (this.challenge.results.winner.name === bet.winner.name) {
        if (this.challenge.results.winner.name == this.challenge.line.name) {
          // console.log(this.convert(this.challenge.lineFOdds))
          earnings += this.convert(this.challenge.lineFOdds)
        } else {
          // console.log(this.convert(this.challenge.lineAOdds))
          earnings += this.convert(this.challenge.lineAOdds)
        }
      } else {
        earnings -= 1
      }
      
      // SPREAD
      if (this.challenge.results.spread.name === bet.spread.name) {
        if (this.challenge.results.spread.name == this.challenge.spread.name) {
          // console.log(this.convert(this.challenge.spreadFOdds))
          earnings += this.convert(this.challenge.spreadFOdds)
        } else {
          // console.log(this.convert(this.challenge.spreadAOdds))
          earnings += this.convert(this.challenge.spreadAOdds)
        }
      } else {
        earnings -= 1
      }

      // TOTALPOINTS
      if (this.challenge.results.totalpoints === bet.totalpoints) {
        if (this.challenge.results.totalpoints == "OV") {
          // console.log(this.convert(this.challenge.totalFOdds))
          earnings += this.convert(this.challenge.totalFOdds)
        } else {
          // console.log(this.convert(this.challenge.totalAOdds))
          earnings += this.convert(this.challenge.totalAOdds)
        }
      } else {
        earnings -= 1
      }

      return (earnings).toFixed(2)
    }
  }
};
</script>

<style scoped>
.picture {
  width:35px;
  height:35px;
  object-fit:cover;
  border-radius:50%;
}
.wrapper {
  border-radius: 4px;
  /* background-color: #242424; */
  padding: 10px;
  /* box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22); */
  border: 1px solid #303030;
  margin-bottom: 10px;
  padding-bottom: 20px;
}
.logo-box {
  width: 100%;
  height:25px;
}
.progress {
  height: 6px;
}
.table-success {
  color: #00bc8c;
  background-color: transparent;
}
td
{
 max-width: 50px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}
.stats {
  width: 100%;
  height:25px;
}
.alignleft {
  margin: 0;
	float: left;
}
.alignright {
  margin: 0;
	float: right;
}
.progress-bar {
  background-color: #00bc8c;
}
.odd-container {
  position:relative; 
  border: 1px solid gray;
  background-color: #222222;
  border-radius: 5px;
  margin: 1px;
  height: 45px;
  width: 100%;
}
.odds {
  vertical-align: middle;
  height: 100%;
  width: 100%;
  position: absolute;
  text-align: center;
}
.money-line {
  font-size: 10px;
  color: gray;
}
.hit {
  border: 2px solid #00bc8c;
}

.table th {
  border: 0px solid #444;
}
</style>